import { makeStyles } from "@material-ui/core/styles";
import { pointer } from "d3";
const drawerWidth = 410;
//Material UI styling
export const useStyles = makeStyles((theme) => ({
  //GLOBAL
  root: {
    display: "flex",
  },
  // SITE SOP STYLING
  sop_section: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginRight: "20px",
  },
  sop_textfield: {
    width: "320px",
    height: "35px",
  },
  site_sop: {
    margin: "0 5px",
  },
  camera_sop: {
    margin: "0 10px",
  },
  field_section: {
    display: "flex",
  },
  site_sop_list: {
    marginTop: "10px",
    transform: "translate(-25px,0)",
  },
  sop_btn: {
    width: "120px",
    height: "35px",
    marginLeft: "0px",
    marginTop: "0px",
    backgroundColor: "#6287E8",
    color: "white",
  },
  selectTable: {
    borderBottom: "none",
    borderColor: "rgba(0,0,0,0)",
    backgroundColor: "rgba(0,0,0,0)",
  },
  ResourcesRoot: {
    width: "25%",
    height: "100vh",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  tableRow: {
    height: 30,
  },
  marker_fill: {
    fill: "#e30960",
  },
  non_marker_fill: {
    fill: "#3f51b5",
  },
  imgGallery: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  contollers: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  menu_button: {
    width: 160,
    height: 35,
    borderWidth: 1,
    borderColor: "rgba(21, 50, 95, 0.59)",
    borderStyle: "solid",
    marginBottom: 20,
    borderRadius: 20,
    color: "#ffffff",
    "&:hover": {
      // transform: 'scale(1.1)',
      backgroundColor: "#eeeeee",
      borderColor: "#f6f6f6",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
    },
  },
  menu_blank_button: {
    width: 160,
    height: 35,
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.59)",
    borderStyle: "solid",
    marginBottom: 20,
    borderRadius: 20,
    color: "#ffffff",
    "&:hover": {
      // transform: 'scale(1.1)',
      backgroundColor: "#eeeeee",
      borderColor: "#f6f6f6",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
    },
  },
  space_smallest: {
    height: 10,
    margin: 4,
  },
  menu_container: {
    // padding: 10,
    paddingTop: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    // boxShadow: '0 2px 8px rgba(0,0,0,0.3)',

    width: 550,
    borderRadius: 24,
    borderBottom: 0,
    // backgroundColor: '#f6f6f6',
  },
  muteInputs: {
    display: "flex",
    margin: 40,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  twoButtonsInARow: {
    display: "flex",
    justifyContent: "space-around",
  },
  singleOkButton: {
    display: "flex",
    justifyContent: "center",
  },
  disabledCheckbox: {
    color: "blue",
  },
  smtpSettingsTabHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: 18,
  },
  smtpSettingsTabOddTypo: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
    backgroundColor: "d6d6d6",
  },
  smtpSettingsTab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    marginTop: 67,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 67,
  },
  login: {
    height: 2000,
    width: "100%",
    padding: 50,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  bold_text_white_blue_background: {
    color: "#FFFFFF",
    backgroundColor: "#4773B8 100%",
    borderColor: "#FFFFFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  ruleset_content: {
    width: `calc(100% - 300px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    margin: "0 auto",
    padding: theme.spacing(3),
  },
  tests_content: {
    width: `calc(100% - 10px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  billing_content: {
    width: `calc(100% - 10px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root_dashboard: {
    display: "flex",
    flexGrow: 1,
  },
  alert_card_unreviewed: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: 12,
    color: "#1f3161",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#b4d5fa",
    margin: 3,
    minWidth: 350,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paper2: {
    paddingLeft: 5,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  //TEXT
  button_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#FFFFFF",
    marginTop: 20,
    marginBottom: 20,
    background: "#4d8fff",
    width: 200,
  },
  button_text_blue: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#FFFFFF",
    border: "solid 1px white",
    marginBottom: 20,
    background: "transparent",
    width: 200,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: 12,
    color: "#15325F",
  },
  chip: {
    margin: 2,
    fontSize: 12,
    color: "#15325F",
  },
  chip1: {
    margin: 2,
    fontSize: 12,
    color: "white",
  },
  bold_text_edit_modal_header: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 19,
    color: "#15325F",
  },

  bold_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#2E4FA5",
    // alignItems: 'center'
  },

  bold_text_group_id: {
    color: "#ffffff",
  },

  bold_text_sites: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#2E4FA5",
    // alignItems: 'center'
  },

  bold_text_shared_hubs: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#2E4FA5",
  },

  text_site_hubcount: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#2E4FA5",
    marginLeft: 20,
  },

  bold_text_billing: {
    color: "#3f51b5",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  bold_text_white_billing: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // alignItems: 'center'
  },
  bold_text_lower: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    textTransform: "lowercase",

    // alignItems: 'center'
  },
  bold_text_lower_small: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    textTransform: "lowercase",
    cursor: "pointer",

    // alignItems: 'center'
  },

  button_container_small: {
    margin: 10,
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    flexShrink: 1,
  },
  button_container_small2: {
    margin: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexShrink: 1,
  },
  button_container_small3: {
    // margin: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexShrink: 1,
  },
  cancel_button2: {
    width: 120,
    height: "fit-content",
    marginBottom: 20,
    color: "#15325F",

    // borderRadius: 20,
  },
  cancel_button3: {
    width: 120,
    height: "fit-content",
    marginBottom: 20,
    color: "#15325F",
    borderRadius: 20,
  },
  mute_button: {
    width: 120,
    height: "fit-content",
    marginBottom: 20,
    // borderRadius: 20,
    marginLeft: 10,
    background:
      "linear-gradient(90deg, rgb(71, 115, 184) 0%, rgba(57, 72, 158, 0.98) 100%)",
    color: "white",
    "&:hover": {
      backgroundColor: "#15325F",
      borderColor: "#ffff",
    },
  },
  hover: {
    padding: 3,
    paddingLeft: 15,
    borderRadius: 10,
    marginTop: 15,
    width: "100%",
    "&:hover": {
      marginTop: 15,
      backgroundColor: "#ecf7fc",
      borderColor: "#ffff",
      boxShadow: "0 2px 8px rgba(0, 0, 100, 0.3)",
      p: {
        backgroundColor: "white",
      },
    },
  },
  hover2: {
    backgroundColor: "#f6f6f6",
    borderRadius: 20,
    minHeight: 40,
    padding: 10,
    "&:hover": {
      backgroundColor: "white",
      borderRadius: 20,
      minHeight: 40,
      padding: 10,
    },
  },
  mute_button3: {
    width: 120,
    height: "fit-content",
    marginBottom: 20,
    borderRadius: 20,
    marginLeft: 10,
    background:
      "linear-gradient(90deg, rgb(71, 115, 184) 0%, rgba(57, 72, 158, 0.98) 100%)",
    color: "white",
    "&:hover": {
      backgroundColor: "#15325F",
      borderColor: "#ffff",
    },
  },
  hov: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
      borderColor: "#ffff",
      cursor: "pointer",
    },
  },
  muteInput: {
    width: 80,
    borderWidth: 1,
    textAlign: "center",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    background: "inherit",
    borderColor: "grey",
    outline: "none",
    color: "grey",
    fontSize: 13,
    // fontWeight: 'bold'
  },
  bold_text_left_margin: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginLeft: 15,
  },
  bold_text_top_margin: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginTop: 10,
  },
  bold_text_red: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FF0000",
  },
  bold_text_amber: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFBF00",
  },
  bold_text_right: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    align: "right",
  },
  bold_error_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "red",
  },
  bold_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 13,
    color: "#FFFFFF",
  },
  logo_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    marginTop: -25,
    marginLeft: 120,
  },
  logo_text_reg_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    marginTop: -25,
    marginLeft: 120,
  },

  ite_text_sensorcount: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#2E4FA5",
    marginLeft: 20,
  },
  standard_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
  },
  medium_text_bold: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  smaller_text_bold: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  center_text: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  center_text_white: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
  },
  //EXPANSION DRAWERS
  header: {
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 1,
    alignItems: "center",
  },
  location_header: {
    display: "flex",
    justifyContent: "flex-start",
    background: "#BDCAEA",
    flexGrow: 1,
    alignItems: "center",
    borderRadius: "6px 6px 0px 0px",
  },
  site_header: {
    background: "#e7edf4",
    marginBottom: 10,
  },
  expansionpanel: {
    background: "#d9d9db",
    marginTop: 15,
    height: 35,
  },
  header_icon: {
    marginTop: 1,
    marginLeft: 10,
  },
  header_icon_white: {
    marginTop: 1,
    marginLeft: 10,
    color: "#FFFFFF",
  },
  header_text: {
    marginTop: 11,
    fontFamily: "Open Sans",
    height: 40,
    alignItems: "center",
    fontSize: 14,
    fontWeight: 600,
    width: "100%",
    paddingLeft: 12,
    color: "#15325F",
  },
  header_text_error: {
    marginTop: 11,
    fontFamily: "Open Sans",
    height: 33,
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 12,
    color: "#FF0000",
  },
  header_text_icons: {
    fontFamily: "Open Sans",
    height: 33,
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 12,
    color: "#15325F",
    lineHeight: "normal",
    marginTop: -10,
  },
  header_text_white: {
    marginTop: 15,
    fontFamily: "Open Sans",
    height: 33,
    fontSize: 14,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 12,
    color: "#FFFFFF",
    marginRight: 10,
  },

  // CCS STYLES FOR DEFAULT RULES
  default_rules_header: {
    display: "flex",
    borderRadius: "5px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#1f3161",
    opacity: 0.6,
    height: 35,
    marginTop: 1,
    marginBottom: 1,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // transform: 'scale(1.017)',
    },
  },
  header_center_text_site: {
    textAlign: "center",
    fontFamily: "Open Sans",
    width: "100%",
    fontSize: 12,
    fontWeight: "bold",
    color: "#15325F",
    height: 14,
    margin: "auto",
    marginTop: -30,
  },

  site_header_icon: {
    textAlign: "center",
    paddingTop: 35,
    margin: "auto",
    color: "#15325F",
    height: 100,
  },
  site_product_icon: {
    textAlign: "center",
    margin: "auto",
    color: "#15325F",
    height: 100,
  },
  header_center_text: {
    marginTop: -3,
    fontFamily: "Open Sans",
    height: 33,
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 12,
    color: "#15325F",
  },
  sub_header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  title_icon: {
    marginTop: 3,
    marginLeft: 10,
    display: "flex",
    color: "#15325F",
    alignItems: "center",
  },
  search_icon: {
    marginTop: 9,
    marginLeft: 13,
    display: "flex",
    color: "#15325F",
    alignItems: "center",
  },
  grab_icon: {
    cursor: "grab",
    marginTop: 3,
    marginLeft: 10,
    display: "flex",
    color: "#15325F",
    alignItems: "center",
  },
  expansion_shelf: {
    cursor: "grab",
    display: "flex",
    width: "100%",
    height: 50,
    marginTop: -5,
    marginBottom: 0,
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    overflow: "hidden",
    background: "#EFF2F9",
    borderRadius: 0,
  },
  rule_expansion_shelf: {
    cursor: "grab",
    display: "flex",
    width: 850,
    minHeight: 30,
    marginTop: 2,
    marginBottom: 2,
    justifyContent: "space-evenly",
  },
  rule_expansion_shelf_reponsive: {
    cursor: "grab",
    display: "flex",
    width: "100%",
    minHeight: 30,
    marginTop: 2,
    marginBottom: 2,
    justifyContent: "space-evenly",
  },
  sensor_expansion_shelf: {
    cursor: "grab",
    display: "flex",
    width: "100%",
    height: 25,
    justifyContent: "space-between",
  },
  even_shelf: {
    cursor: "grab",
    width: "100%",
    height: 38,
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  expansion_area: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  rule_name: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "25%",
    justifyContent: "flex-start",
  },
  rule_container: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "70%",
    justifyContent: "space-between",
  },
  rule_item: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    justifyContent: "space-evenly",
    paddingLeft: 15,
    paddingRight: 15,
  },
  rule_menu: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "5%",
    justifyContent: "flex-end",
  },
  shelf_name: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "35%",
    justifyContent: "flex-start",
    margin: 19,
  },
  shelf_container: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "60%",
    justifyContent: "space-evenly",
  },

  shelf_item: {
    display: "flex",
    alignItems: "center",
    color: "#2E4FA5",
    gap: "4px",
    padding: "4px 0",
  },

  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
  },

  bold_text_subgroup: {
    color: "#2E4FA5",
    fontSize: 14,
    marginLeft: 20,
    fontWeight: "bold",
    fontFamily: "Open Sans",
    fontStyle: "normal",
  },

  shelf_item_check_icon: {
    marginLeft: 20,
    color: "#2E4FA5",
    fontSize: 10,
  },

  shelf_item_logo: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    justifyContent: "space-evenly",
  },
  shelf_image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shelf_menu: {
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    width: "20%",
    justifyContent: "flex-end",
  },

  sitemenuText: {
    color: "#ffffff",
  },

  sitemenu_svg_icon: {
    "& path": {
      fill: "#2E4FA5",
      width: 5,
      height: 25,
    },
  },

  shelf_icon_group: {
    "& path": {
      fill: "#2E4FA5",
    },
  },

  shelf_icon: {
    paddingRight: 20,
  },
  site_icon: {
    height: 100,
    marginTop: 5,
  },
  site_menu: {
    marginLeft: -15,
  },
  product_icon: {
    height: 42,
  },
  product_logo: {
    height: 150,
  },
  //OTHER AREAS
  login_container: {
    width: 480,
    height: 640,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 35,
    background: "#1f316133",
    borderRadius: 10,
  },
  register_container: {
    width: 480,
    // height: 520,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 35,
    background: "#1f316133",
    borderRadius: 10,
  },
  register_reg_container: {
    width: 480,
    // height: 520,
    marginLeft: "auto",
    marginRight: "auto",
    background: "#1f316133",
    borderRadius: 10,
  },
  icon_container: {
    width: 185,
    marginTop: 33,
    marginLeft: 18,
    display: "flex",
    justifyContent: "space-around",
  },
  instruction_text: {
    // width: 185,
    marginTop: 12,
    marginLeft: 18,
    display: "flex",
    justifyContent: "space-around",
  },
  button_container: {
    marginTop: 24,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-around",
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  button_container_four: {
    marginTop: 24,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    // justifyContent: 'space-between',
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  row_container: {
    display: "flex",
    justifyContent: "space-around",
  },

  radio_container: {
    display: "flex",
    justifyContent: "flex-start",
  },
  modal_dialog: {
    margin: "auto",
    maxWidth: "md",
    fullWidth: true,
  },
  modal_dialog_fixed: {
    margin: "auto",
    minWidth: 680,
    minHeight: 480,
    fullWidth: true,
  },
  modal_dialog_wide: {
    margin: "auto",
    minWidth: 960,
    minHeight: 480,
  },
  large_modal_dialog: {
    margin: "auto",
  },
  medium_panel: {
    margin: "auto",
    width: "60%",
  },
  main_drawer_header: {
    display: "flex",
    marginTop: 25,
  },
  card_container: {
    display: "flex",
    marginTop: 0,
    padding: 20,
    minWidth: 660,
  },
  placeholder: {
    display: "flex",
    marginTop: 25,
    marginLeft: "auto",
    marginRight: "auto",
  },
  //OTHER OBJECTS:
  image_component: {
    margin: "auto",
    padding: 15,
  },
  delivery_endpoint: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    textOverflow: "ellipsis",
    marginTop: 1,
  },
  form_component: {
    padding: 15,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
  },
  form_component_small: {
    padding: 15,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
    width: "25%",
  },
  form_component_medium: {
    padding: 15,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
    width: "50%",
  },
  form_component_medium2: {
    padding: 15,
    // margin: 20,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
    width: "50%",
    // "&:hover": {
    //   transform: "scale(1.03)",
    //   transition: "transform 0.5s ease-out",
    // },
  },
  form_component_checkbox: {
    padding: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
  },
  form_component_checkbox_left_margin: {
    padding: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#15325F",
    marginLeft: 15,
  },
  save_button: {
    width: 85,
    marginLeft: theme.spacing(2),
  },
  cancel_button: {
    width: 85,
    marginLeft: theme.spacing(2),
  },
  cancel_button_billing: {
    textOverflow: "ellipsis",
    // marginLeft: theme.spacing(2),
  },
  cancel_button_top_margin: {
    margin: 20,
    width: 85,
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  cancel_button_top_margin_disabled: {
    margin: 20,
    width: 85,
    opacity: 0.5,
    cursor: "not-allowed",
  },
  interval_selector: {
    width: 350,
    height: 40,
    marginLeft: 30,
    marginRight: 35,
    marginBottom: 20,
  },
  disabled: {
    cursor: "not-allowed",
  },
  shelf_item_top: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    justifyContent: "space-evenly",
    paddingTop: 20,
  },
  //TIME SCHEDULERS
  schedule_card: {
    height: 250,
    marginBottom: 10,
    maxWidth: 300,
  },
  schedule_horisontal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },

  add_schedule: {
    paddingLeft: 45,
    marginTop: -35,
  },
  close_icon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    fill: "#15325F",
  },
  icon_cicle: {
    "& path": {
      fill: "#2E4FA5",
      stroke: "black",
      strokeWidth: 1,
    },
  },

  pointer: {
    height: 30,
  },
  grab: {
    cursor: "grab",
  },
  pointer_top_margin: {
    marginTop: 20,
    cursor: "pointer",
  },
  red: {
    color: "#ee4623",
  },
  green: {
    color: "#3f54a5",
  },
  amber: {
    color: "#b4d5fa",
  },
  unknown: {},
  space: {
    height: 60,
    margin: 4,
  },
  space_smaller: {
    height: 30,
    margin: 4,
  },
  // space_smallest: {
  //   height: 10,
  //   margin: 4,
  // },
  floatleft: {
    float: "left",
  },
  center: {
    margin: "auto",
    display: "block",
  },
  margin25: {
    marginLeft: 25,
    marginTop: 25,
  },
  marginleft25: {
    marginLeft: 25,
  },
  expansionbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#2E4FA5",
    height: 50,
    marginTop: 0,
    marginBottom: 0,
    minWidth: "100%",
    padding: 9,
    cursor: "pointer",
    borderRadius: "0 0px 8px 8px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // transform: 'scale(1.017)',
    },
  },
  expansionbar_responsive: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#2E4FA5",
    height: 50,
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
    cursor: "pointer",
    borderRadius: 0,
    padding: 9,
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // transform: 'scale(1.017)',
    },
  },
  spaceExpansion: {
    // marginLeft: '5%',
    backgroundColor: "red",
    cursor: "pointer",
    flexGrow: 1,
  },
  expansionbar_medium: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#1f3161",
    opacity: 0.8,
    height: 35,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: 900,
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    },
  },
  expansionbar_medium_alert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#1f3161",
    opacity: 0.8,
    height: 35,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    },
  },
  expansionbar_small: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#1f3161",
    opacity: 0.6,
    height: 35,
    marginTop: 1,
    marginBottom: 1,
    width: 850,
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // transform: 'scale(1.017)',
    },
  },
  expansionbar_small_alert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#1f3161",
    opacity: 0.6,
    height: 35,
    marginTop: 1,
    marginBottom: 1,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
      // transform: 'scale(1.017)',
    },
  },
  expansioncontent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ffffff",
  },
  expansioncontent_whole_width: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ffffff",
    width: "100%",
  },
  icon_white: {
    color: "#FFFFFF",
    width: 25,
    height: 25,
  },
  icon_blue: {
    color: "#15325F",
  },
}));
//Material UI styling.
export const searchStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 566,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  center: {
    margin: "auto",
    display: "block",
  },
  svg_margin: {
    marginLeft: 90,
    marginTop: 80,
  },
}));
